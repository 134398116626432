import { useUrls } from '@/composables/useUrls';
import { useApiRegions } from '~/composables/useApiRegions';
import { useRoutes } from '~/composables/useRoutes.js';

export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.server) {
    const nuxtApp = useNuxtApp();

    // If the error is already being handled, skip the validation.
    if (nuxtApp.payload.error) {
      return;
    }

    // ## COMPOSABLES
    const { getRegionByName } = useApiRegions();
    const { useRouteManager } = useRoutes();
    const { useUrl } = useUrls();

    const urlParams = to?.params;
    const urlQuery = to?.query;
    const urlPath = to?.path.toLowerCase();

    // VALIDATE PROPERTY TYPE
    // If invalid property type, then default to 'hostels' property type (according to the user language);
    const propertyTypeObj = useRouteManager.validatePropertyTypeParam(urlParams.propertyType);

    // VALIDATE REGION WITH CALL TO SPAPI
    const locationObj = await getRegionByName(propertyTypeObj?.key, urlParams.country, urlParams.region);

    if (locationObj?.id) {
      // REGION SEEMS TO BE A VALID ONE.
      // CHECK IF REQUESTED URL MATCHES THE USER LANGUAGE FORMAT
      const regionPageUrlForCurrentLang = useUrl.getRegionPageUrl(
        {
          propertyTypeSlug: propertyTypeObj.slug,
          urlFriendlyContinent: locationObj.urlFriendlyContinent,
          urlFriendlyCountry: locationObj.urlFriendlyCountry,
          urlFriendlyRegion: locationObj.urlFriendlyName,
        },
        urlQuery,
      );

      const regionPagePathForCurrentLang = new URL(regionPageUrlForCurrentLang).pathname.toLowerCase();

      if (regionPagePathForCurrentLang !== urlPath) {
        // REQUESTED URL DOES NOT MATCH USER LANGUAGE FORMAT
        // REDIRECT USER TO THE APPROPRIATE URL FORMAT
        navigateTo(regionPagePathForCurrentLang, {
          redirectCode: 301,
          external: false,
        });
      }
    } else {
      // REGION IS NOT FOUND, THROW 404 TO CHECK THE REDIRECTION API
      const errorDescription = `### No such Region '${urlParams.region}' in Country '${urlParams.country}'`;

      let urlForTranslation = urlPath;
      if (!urlParams.propertyType.match(/\.php$/)) {
        urlForTranslation = urlPath.replace(`${urlParams.propertyType}/`, `${propertyTypeObj.slug}/`);
      }

      console.error(errorDescription);

      throw createError({
        statusCode: 404,
        statusMessage: errorDescription,
        data: useRouteManager.enrichedErrorResponse(errorDescription, urlForTranslation),
      });
    }
  }
});
